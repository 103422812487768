export const Team = ({
    state: () => ({
        team: [
            {mask: 'mask-1', img: 'Dmitriy.jpg', name: 'Дмитрий', position: 'Chief executive officer, основатель'},
            // {mask: 'mask-2', img: "Sophia.jpg", name: "Софья", position: "Chief commercial officer, партнер"},
            // {mask: 'mask-3', img: 'Sergey.jpg', name: 'Сергей', position: 'Project manager, партнер'},
            // {mask: 'mask-1', img: 'Vlad.jpg', name: 'Влад', position: 'Chief technical officer'},
            // {mask: 'mask-3', img: 'Dasha_2.jpg', name: 'Даша', position: 'Head of QA'},
            // {mask: 'mask-4', img: 'maks_1.jpg', name: 'Макс', position: 'Head of Support'},
            // {mask: 'mask-5', img: 'Masha.jpg', name: 'Маша', position: 'HR business partner'},
            // {mask: 'mask-6', img: 'Pasha.jpg', name: 'Паша', position: 'Chef mediation officer, партнер'},
            // {mask: 'mask-7', img: 'Marina.jpg', name: 'Марина', position: 'Head of sales department'},
            {mask: 'mask-2', img: 'Lesha.jpg', name: 'Лёша', position: 'Senior support engineer'},
            {mask: 'mask-1', img: 'Tanya.jpg', name: 'Таня', position: 'Сhief financial officer'},
            // {mask: 'mask-2', img: 'Diana.jpg', name: 'Диана', position: 'QA'},
            // {mask: 'mask-3', img: 'Katya.jpg', name: 'Катя', position: 'QA'},
            // {mask: 'mask-4', img: 'Misha.jpg', name: 'Миша', position: 'Senior technical support and implementation'},
            {mask: 'mask-5', img: 'Vanya.jpg', name: 'Ваня', position: 'Technical support and implementation'},
            // {mask: 'mask-6', img: 'Oleg.jpg', name: 'Олег', position: 'Computer vision developer'},
            // {mask: 'mask-7', img: 'Yana.jpg', name: 'Яна', position: 'Frontend developer'},
            // {mask: 'mask-2', img: 'Misha_2.jpg', name: 'Миша', position: 'Backend developer'},
            // {mask: 'mask-4', img: 'Roma.jpeg', name: 'Рома', position: 'Backend developer'},
            // {mask: 'mask-3', img: 'Olya.jpg', name: 'Оля', position: 'Heritage projects support'},
            // {mask: 'mask-4', img: 'Nadya.jpg', name: 'Надя', position: 'Tech writer & localization'},
            {mask: 'mask-6', img: 'Liza.jpg', name: 'Лиза', position: 'Office manager'},
            {mask: 'mask-4', img: 'Nastya-support.jpg', name: 'Настя', position: 'Junior technical support'}
            // {mask: 'mask-2', img: 'Denis.jpg', name: 'Денис', position: 'Design & visualization'},
        ],
    }),
    getters: {
        getTeam(state) {
            return state.team
        }
    }
})